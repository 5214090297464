<template>
  <div class="la-spa-set-date">
    <fd-form class="card" @submit="submitForm">
      <modal-body class="p-2">
        <h4 class="mb-4">{{ isEdit ? "Edit" : "Set" }} {{ type }} Date</h4>

        <fd-date-picker
          v-model="agreementDate"
          class="col-12 px-1 mb-2"
          :label="`${type} Date`"
          name="agreementDate"
          :max="$moment(maxDate).format('YYYY-MM-DD')"
          :validators="[
            validator.required,
            (val) => validator.dateMax(val, maxDate)
          ]"
        >
        </fd-date-picker>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <fd-button @click="$emit('cancel')">Cancel</fd-button>
        <fd-button type="submit" class="main ml-1"
          >{{ isEdit ? "Update" : "Set" }} Date</fd-button
        >
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  dateMin,
  dateMax
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    type: {
      type: String,
      required: true
      // SPA, LA
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    date: {
      type: String
    },
    maxDate: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      agreementDate: "",

      validator: {
        required: required,
        dateMin: dateMin,
        dateMax: dateMax
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.isEdit) {
        this.agreementDate = this.date;
      }
    },
    submitForm() {
      let payload;
      if (this.type == "LA") {
        payload = { signLoanAgreementDate: this.agreementDate };
      } else if (this.type == "SPA") {
        payload = { signSalesPurchaseAgreementDate: this.agreementDate };
      }

      this.$emit("submit", this.type, payload);
    }
  }
};
</script>

<style lang="scss">
.la-spa-set-date {
  min-width: 400px;
}
</style>
